<template>
  <div class="recharge-prolong">
    <div class="content-main">
      <park-header></park-header>
      <!--<div class="rp-title-wrap">-->
        <!--<div class="rp-title-label">充值延期</div>-->
        <!--<page-title :title="'充值延期'"></page-title>-->

      <!--</div>-->
      <div class="rp-profile">
        <go-back-title :title="'充值延期'"></go-back-title>
        <van-tabs v-model:active="tabs.active">
          <van-tab title="月租车" v-if="tabs.showVh" :disabled="tabs.disabled">
            <van-form @submit="onSubmitVh">
              <van-field
                v-model="vhForm.plateNumber"
                name="plateNumber"
                label="车牌号码"
                class="to-upper"
                @focus="showKeyboard = true"
                placeholder="请输入车牌号码"
                @input="vhForm.plateNumber = vhForm.plateNumber.toUpperCase().replace(/\s/g,'')"
                :rules="[{ required: true, pattern, message: '请输入正确的车牌' }]"
              />
              <van-field
                readonly
                clickable
                :value="vhForm.month"
                label="充值月数"
                name="month"
                :rules="[{ required: true}]"
                placeholder="请选择充值月数"
              @click="vhForm.showPicker = true"/>
              <popup-picker
                :show-picker.sync="vhForm.showPicker"
                :columns="vhForm.monthList"
                :value.sync="vhForm.month"
              ></popup-picker>
              <div class="history-wrap" v-if="Array.isArray(historyPlateNumbers) && historyPlateNumbers.length">
                <van-tag plain :key="p" v-for="p in historyPlateNumbers" @click="changePlateNumber(p)">
                  {{p}}
                </van-tag>
                <van-tag @click="clearVhHistory">清空</van-tag>
              </div>
              <div style="margin: 3rem 1.6rem;">
                <van-button :disabled="disabled" round block type="info" native-type="submit">确定</van-button>
              </div>

            </van-form>
          </van-tab>
          <van-tab title="分组" v-if="tabs.showGroup" :disabled="tabs.disabled">
            <van-form @submit="onSubmitGroup">
              <van-field
                v-model="groupForm.name"
                name="group"
                label="分组名称"
                placeholder="请输入分组名称（一般为户号）"
                @input="groupForm.name = groupForm.name.replace(/\s/g,'')"
                :rules="[{ required: true}]"
              />
              <van-field
                readonly
                clickable
                :value="groupForm.amount"
                label="金额(元)"
                name="amount"
                :rules="[{ required: true}]"
                placeholder="请选择充值金额（元）"
                @click="groupForm.showPicker = true"/>
              <popup-picker
                :show-picker.sync="groupForm.showPicker"
                :columns="groupForm.moneyList"
                :value.sync="groupForm.amount"
              ></popup-picker>
              <!--historyGroupName-->
              <div class="history-wrap" v-if="Array.isArray(historyGroupName) && historyGroupName.length">
                <van-tag plain :key="p" text-color="#999" v-for="p in historyGroupName" @click="changeGroupName(p)">
                  {{p}}
                </van-tag>
                <van-tag @click="clearGroupHistory">清空</van-tag>
              </div>
              <div style="margin: 3rem 1.6rem;">
                <van-button :disabled="disabled" round block type="info" native-type="submit">确定</van-button>
              </div>
            </van-form>
          </van-tab>
          <van-tab title="储值车" v-if="tabs.showStore" :disabled="tabs.disabled">
            <van-form @submit="onSubmitStore">
              <van-field
                v-model="storeForm.plateNumber"
                name="plateNumber"
                label="车牌号码"
                class="to-upper"
                @focus="showKeyboard = true"
                placeholder="请输入车牌号码"
                @input="storeForm.plateNumber = storeForm.plateNumber.toUpperCase().replace(/\s/g,'')"
                :rules="[{ required: true, pattern, message: '请输入正确的车牌' }]"
              />
              <van-field
                readonly
                clickable
                :value="storeForm.amount"
                label="金额(元)"
                name="amount"
                :rules="[{ required: true}]"
                placeholder="请选择充值金额（元）"
                @click="storeForm.showPicker = true"/>
              <popup-picker
                :show-picker.sync="storeForm.showPicker"
                :columns="storeForm.moneyList"
                :value.sync="storeForm.amount"
              ></popup-picker>
              <van-field
                readonly
                clickable
                :value="storeForm.month"
                label="充值月数"
                name="month"
                :rules="[{ required: true}]"
                placeholder="请选择充值充值月数"
                @click="storeForm.showMonthPicker = true"/>
              <popup-picker
                :show-picker.sync="storeForm.showMonthPicker"
                :columns="storeForm.monthList"
                :value.sync="storeForm.month"
              ></popup-picker>
              <div class="history-wrap" v-if="Array.isArray(historyStorePlate) && historyStorePlate.length">
                <van-tag plain :key="p" v-for="p in historyStorePlate" @click="changeStorePlate(p)">
                  {{p}}
                </van-tag>
                <van-tag @click="clearStoreHistory">清空</van-tag>
              </div>
              <div style="margin: 3rem 1.6rem;">
                <van-button :disabled="disabled" round block type="info" native-type="submit">确定</van-button>
              </div>

            </van-form>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <van-dialog v-model="qrShow" title="付款" @confirm="qrConfirm" confirm-button-text="关闭">
      <img :src="qrImg" />
    </van-dialog>

    <!--<van-popup :style="{ height: '60%' }"-->
      <!--v-model:show="vhForm.popupShow.noReg">{{vhForm.popupShow.noRegTxt}}</van-popup>-->
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import popupPicker from '../../public/popup-picker'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
import * as util from '../../api/util.js'
// import pageTitle from '../../public/page-title'
import QRCode from 'qrcode'
import goBackTitle from '../../public/go-back-title'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  name: 'recharge-prolong',
  components: {
    parkHeader,
    popupPicker,
    // pageTitle
    goBackTitle
  },
  data () {
    return {
      payType: null,
      historyPlateNumbers: [],
      historyStorePlate: [],
      historyGroupName: [],
      pattern: new RegExp('^[' +  common.plateNumberHeader.join('') + '][A-Z][A-Z0-9]{5,6}$'),
      disabled: false,
      tabs: {
        disabled: false,
        active: 0,
        showVh: true,
        showGroup: false,
        showStore: false
      },
      vhForm: {
        showPicker: false,
        plateNumber: '',
        month: 1,
        monthList: [1, 3, 6, 12],
        popupShow: {
          noReg: false,
          noRegTxt: ''
        }
      },
      storeForm: {
        showPicker: false,
        showMonthPicker: false,
        plateNumber: '',
        month: '长期有效',
        monthList: ['长期有效', 1, 3, 6, 12],
        amount: 30,
        moneyList: [30, 50, 100, 200, 300]
      },
      payVHParams: {},
      payGroupParams: {},
      payStoreParams: {},
      groupForm: {
        showPicker: false,
        name: '',
        amount: 100,
        moneyList: [30, 50, 100, 200, 300]
      },
      // showKeyboard: false, // 车牌键盘
      // inputIndex: -1,
      // cardPlateNumber: [],
      isVueReady: false,
      isTypeReady: false,
      topicSuccessFnVh: '',
      topicSuccessFnGroup: '',
      topicSuccessFnStore: '',
      qrShow: false,
      qrImg: ''
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    async onSubmitVh(form) {
      console.log('onSubmitVh', form)
      try {
        let para = {
          plate_number: form.plateNumber,
          month: form.month,
          vh_cate_id: 1,
          sid: window.localStorage.getItem('sid')
        }
        this.tabs.disabled = true
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_info}`;
        await mqtt.subscribe(topic)
        this.disabled = true
        mqtt.Client.config[mqtt.topics.mq_query_charge_info] = this.getVHPayInfo
        let res = await api.axiosRequest(api.urlList.fenglin.query.query_charge_info, para)
        this.$toast.clear()
        this.disabled = false
        this.tabs.disabled = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        this.tabs.disabled = false
        common.showErrorMessage(this, e)
      }
    },
    async onSubmitGroup(form) {
      try {
        console.log('onSubmitGroup', form)
        let para = {
          group_name: form.group,
          amount: form.amount,
          vh_cate_id: 4,
          sid: window.localStorage.getItem('sid')
        }
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_info}`;
        await mqtt.subscribe(topic)
        this.disabled = true
        mqtt.Client.config[mqtt.topics.mq_query_charge_info] = this.getGroupPayInfo
        let res = await api.axiosRequest(api.urlList.fenglin.query.query_charge_info, para)
        this.$toast.clear()
        this.disabled = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    async onSubmitStore(form) {
      console.log('onSubmitVh', form)
      try {
        let para = {
          plate_number: form.plateNumber,
          month: form.month,
          vh_cate_id: 3,
          sid: window.localStorage.getItem('sid')
        }
        this.tabs.disabled = true
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_info}`;
        await mqtt.subscribe(topic)
        this.disabled = true
        mqtt.Client.config[mqtt.topics.mq_query_charge_info] = this.getStorePayInfo
        let res = await api.axiosRequest(api.urlList.fenglin.query.query_charge_info, para)
        this.$toast.clear()
        this.disabled = false
        this.tabs.disabled = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        this.tabs.disabled = false
        common.showErrorMessage(this, e)
      }
    },

    async doPayVH(action, done) {
      console.log(action, done)
      try {
        let params = this.payVHParams
        let amount = params.month_rent * params.month
        await this.getPayWay(localStorage.getItem('sid'))
        let type = this.payType
        // if (util.get_user_agent() == 'wx') {
        //   type = util.pay_type.bjyz
        // } else if (util.get_user_agent() == 'zfb') {
        //   type = util.pay_type.bjyz_zfb
        // }
        let obj = {
          sid: localStorage.getItem('sid'),
          gross_price: amount,
          actually_paid: amount,
          sign: params.sign,
          // code: -1,
          type: type,
          plate_number: params.plate_number,
          subject: '停车收费',
          local_info: {
            sid: localStorage.getItem('sid'),
            plate_number: params.plate_number,
            charge_type: common.chargeType.rent,
            charge_start_time: params.charge_start_time,
            month: params.month
          }
        }
        if (this.topicSuccessFnVh) await mqtt.unsubscribe(this.topicSuccessFnVh)
        this.topicSuccessFnVh = mqtt.topics.mq_charge_success + '@' + encodeURIComponent(params.plate_number)
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnVh}`;
        await mqtt.subscribe(topic)
        console.log('finish-success')
        mqtt.Client.config[this.topicSuccessFnVh] = this.payFinish
        await this.doPay(obj, done)
        this.historyPlateNumbers = common.saveHistoryPlateNumber(params.plate_number)
      } catch (e) {
        console.log(e);
      }
    },

    async doPayGroup(action, done) {
      console.log(action, done)
      try {
        let params = this.payGroupParams
        let amount = params.amount * 100
        await this.getPayWay(localStorage.getItem('sid'))
        let type = this.payType
        // if (util.get_user_agent() == 'wx') {
        //   type = util.pay_type.bjyz
        // } else if (util.get_user_agent() == 'zfb') {
        //   type = util.pay_type.bjyz_zfb
        // }
        let obj = {
          sid: localStorage.getItem('sid'),
          gross_price: amount,
          actually_paid: amount,
          sign: params.sign,
          // code: -1,
          type: type,
          group_name: params.group_name,
          subject: '停车收费',
          local_info: {
            sid: localStorage.getItem('sid'),
            group_name: params.group_name,
            charge_type: common.chargeType.group
          }
        }
        if (this.topicSuccessFnGroup) await mqtt.unsubscribe(this.topicSuccessFnGroup)
        this.topicSuccessFnGroup = mqtt.topics.mq_charge_success + '@' + encodeURIComponent(params.group_name)
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnGroup}`;
        await mqtt.subscribe(topic)
        mqtt.Client.config[this.topicSuccessFnGroup] = this.payFinish
        await this.doPay(obj, done)
        this.historyGroupName = common.saveHistoryGroupName(params.group_name)
      } catch (e) {
        console.log(e);
      }
    },
    async doPayStore(action, done) {
      console.log(action, done)
      try {
        let params = this.payStoreParams
        let amount = this.storeForm.amount * 100
        await this.getPayWay(localStorage.getItem('sid'))
        let type = this.payType
        // if (util.get_user_agent() == 'wx') {
        //   type = util.pay_type.bjyz
        // } else if (util.get_user_agent() == 'zfb') {
        //   type = util.pay_type.bjyz_zfb
        // }
        let obj = {
          sid: localStorage.getItem('sid'),
          gross_price: amount,
          actually_paid: amount,
          sign: params.sign,
          // code: -1,
          type: type,
          plate_number: params.plate_number,
          subject: '停车收费',
          local_info: {
            sid: localStorage.getItem('sid'),
            plate_number: params.plate_number,
            charge_type: common.chargeType.store,
            charge_start_time: params.charge_start_time,
            month: params.month
          }
        }
        if (this.topicSuccessFnStore) await mqtt.unsubscribe(this.topicSuccessFnStore)
        this.topicSuccessFnStore = mqtt.topics.mq_charge_success + '@' + encodeURIComponent(params.plate_number)
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnStore}`;
        await mqtt.subscribe(topic)
        console.log('finish-success')
        mqtt.Client.config[this.topicSuccessFnStore] = this.payFinish
        await this.doPay(obj, done)
        this.historyStorePlate = common.saveHistoryStorePlate(params.plate_number)
      } catch (e) {
        console.log(e);
      }
    },
    showVHPayDetail() {
      let msg = this.payVHParams
      let startTimestamp = (new Date(this.payVHParams.charge_start_time.replace(/-/g, '/')).getTime()) + 24 * 3600000
      this.$dialog.confirm({
        // title: '标题',
        showCancelButton: false,
        message: `${msg.plate_number} ${msg.vh_cate_name} ${msg.vh_type_name}\n`
        + `充值规则：${msg.month_rent / 100} 元/月\n`
        + `延期时长：${this.vhForm.month} 个月\n`
        + `充值时间：${common.fmtDate(startTimestamp, 'yyyy-MM-dd')}至${this.addMonths(startTimestamp, this.vhForm.month)}\n`
        + `充值金额：${msg.month_rent * this.vhForm.month / 100}元\n`
        + `请核对后，点击确认`
        ,
        beforeClose: this.doPayVH,
      }).catch(e => console.log(e))
    },
    showStorePayDetail() {
      let msg = this.payStoreParams
      this.$dialog.confirm({
        // title: '标题',
        showCancelButton: false,
        message: `${msg.plate_number} ${msg.vh_cate_name} ${msg.vh_type_name}\n`
        + `延期时长：${this.storeForm.month == '长期有效' ? this.storeForm.month : this.storeForm.month + '个月' } \n`
        // + `充值时间：${common.fmtDate(this.payStoreParams.charge_start_time, 'yyyy-MM-dd')}至${this.addMonths(this.payStoreParams.charge_start_time, this.payStoreParams.month)}\n`
        + `充值金额：${this.storeForm.amount}元\n`
        + `请核对后，点击确认`,
        beforeClose: this.doPayStore,
      }).catch(e => console.log(e))
    },
    doCharge(action, done) {
      console.log('done', done)
      if (action == 'cancel') {
        if(this.payVHParams.inDate != undefined) {
          this.payVHParams.charge_start_time = common.fmtDate(new Date(this.payVHParams.latest_in_time).getTime() - 24 * 3600000, 'yyyy-MM-dd hh:mm:ss');
        }
        else {
          this.payVHParams.charge_start_time = common.fmtDate(new Date().getTime() - 24 * 3600000, 'yyyy-MM-dd hh:mm:ss')
        }

      } else if (action == 'confirm') {
        this.payVHParams.charge_start_time = common.fmtDate(this.payVHParams.effective_date_end, 'yyyy-MM-dd hh:mm:ss')
      }
      this.showVHPayDetail(action)
      done(false)
    },
    addMonths (time, n) {
      let t = new Date(time)
      t.setMonth(t.getMonth() + n)
      return common.fmtDate(t, 'yyyy-MM-dd')
    },
    // 获取信息从mq
    getVHPayInfo(msg) {
      console.log(msg)
      if (msg) {
        if (msg.code == common.resCode.OK) {
          let d = common.fmtDate(new Date(msg.effective_date_end.replace(/-/g, '/')).getTime() + 24 * 3600000, 'yyyy-MM-dd')
          this.payVHParams = msg
          if (msg.month_rent <= 0) {
            return this.$dialog.alert({
              message: '未设置收费，请联系管理员',
            }).then(() => {
              // on close
            })
          }
          if (msg.vh_cate_id == common.vh_cate.store) {
            return this.$dialog.alert({
              message: msg.plate_number + '是储值车',
            }).then(() => {
              // on close
            })
          }
          if (/^299/.test(msg.effective_date_end) || msg.effective_date_end == undefined) {
            return this.$dialog.alert({
              message: `车辆${msg.plate_number}长期有效\n无需充值延期`,
            }).then(() => {
              // on close
            });
          }
          let effective_date_end_timestamp = new Date(msg.effective_date_end.replace(/-/g, '/')).getTime()
          let now = new Date().getTime();
          let message = `${msg.plate_number} 在 ${d} 过期,\n延期从 今天 开始延期，还是从 ${d} 开始延期`;
          let cancelBtnText = "";
          let confirmBtnText = `从${d}开始`;

          if(this.userInfo.showToday == 1) {
            //从今日延期，一直显示
            message = `${msg.plate_number} 在 ${d} 过期,\n延期从 今天 开始延期，还是从 ${d} 开始延期`;
            cancelBtnText = '从今日开始';
            confirmBtnText = `从${d}开始`;
          }
          else if(this.userInfo.showToday == 0) {
            //从今日延期，一直不显示；只从过期日开始
            message = `${msg.plate_number} 从 ${d} 开始延期`
            cancelBtnText = '';
            confirmBtnText = `从${d}开始`;
          }
          else {
            //从今日/入场日 延期，进行智能判断
            if (msg.amount > 0) {
              // 车辆在场，进行充值延期
              let inTimestamp = (new Date(msg.latest_in_time.replace(/-/g, '/'))).getTime()
              let inDesc = `入场时间:${common.fmtDate(msg.latest_in_time, 'MM-dd hh:mm')} `;
              if (inTimestamp <= effective_date_end_timestamp) {
                //入场时，车辆有效期有效
                if (now > effective_date_end_timestamp) {
                  // 停车期间，今日已经过期，从过期日开始充值
                  message = `${msg.plate_number} 在 ${d} 过期,\n${inDesc}停车期间车牌过期`;
                  cancelBtnText = '';
                  confirmBtnText = `从${d}开始`;
                } else {
                  // 月租车有效，从过期日开始充值
                  message = `${msg.plate_number} 从 ${d} 开始延期`;
                  cancelBtnText = '';
                  confirmBtnText = `从${d}开始`;
                }
              }
              else {
                //入场时，车辆已经过期
                message = `${msg.plate_number} 在 ${d} 过期,\n${inDesc}入场时车牌过期`;
                let inDate = common.fmtDate(new Date(msg.latest_in_time.replace(/-/g, '/')).getTime(), 'yyyy-MM-dd')
                cancelBtnText = `从${inDate}开始`;
                confirmBtnText = `从${d}开始`;
                this.payVHParams.inDate = msg.latest_in_time;
              }
            }
            else {
              //车辆在场外，进行充值延期
              if (effective_date_end_timestamp < now) {
                // 过期月租车不在场
                message = `${msg.plate_number} 在 ${d} 过期,\n延期从 今天 开始延期，还是从 ${d} 开始延期`;
                cancelBtnText = '从今日开始';
                confirmBtnText = `从${d}开始`;
              } else {
                // 月租车未过期
                message = `${msg.plate_number} 从 ${d} 开始延期`
                cancelBtnText = '';
                confirmBtnText = `从${d}开始`;
              }
            }
          }

          this.$dialog.confirm({
            // title: '标题',
            showCancelButton: cancelBtnText.length > 0,
            confirmButtonText: confirmBtnText,
            cancelButtonText: cancelBtnText,
            message: message,
            beforeClose: this.doCharge,
          })
            .catch(e => {
              console.log(e)
              this.payVHParams = {}
            })
        } else {
          this.$dialog.alert({
            // title: '',
            message: msg.msg,
            theme: 'round-button'
          }).then(() => {
            console.log(msg)
          })
        }

      } else {
        this.$toast('请求出错')
      }
    },
    getGroupPayInfo(msg) {
      if (msg) {
        if (msg.code == common.resCode.OK) {
          this.payGroupParams = msg
          let effective
          if (/^299/.test(msg.effective_date_end) || msg.effective_date_end == undefined) {
            effective = '长期'
          } else {
            effective = `${common.fmtDate(msg.effective_date_start, 'yyyy-MM-dd')} ~ ${common.fmtDate(msg.effective_date_end, 'yyyy-MM-dd')}`
          }
          this.$dialog.confirm({
            showCancelButton: false,
            message: `分组名：${msg.group_name}\n`
            + `有效期：${ effective }\n`
            + `当前余额：${(msg.balance / 100).toFixed(2)} 元\n`
            + `充值金额：${this.groupForm.amount} 元\n`
            +  `请核对后，点击确认`,
            beforeClose: this.doPayGroup,
          }).catch(e => {
            console.log(e)
            this.payGroupParams = {}
          })
        } else {
          this.$dialog.alert({
            message: msg.msg,
            theme: 'round-button'
          }).then(() => {
            console.log(msg)
          })
        }

      } else {
        this.$toast('请求出错')
      }
    },
    getStorePayInfo(msg) {
      // console.log('getStorePayInfo', msg)
      if (msg) {
        console.log('msg.vh_cate_id', msg.vh_cate_id)
        console.log('msg.vh_cate_id', msg.code)
        if (msg.code == common.resCode.OK) {
          // let d = common.fmtDate(msg.effective_date_end, 'yyyy-MM-dd')
          this.payStoreParams = msg
          // if (/^299/.test(msg.effective_date_end) || msg.effective_date_end == undefined) {
          //   this.payStoreParams.charge_start_time = common.fmtDate(msg.effective_date_end, 'yyyy-MM-dd hh:mm:ss')
          // } else {
          let start_time = new Date().getTime() - 24 * 3600000
          this.payStoreParams.charge_start_time = common.fmtDate(start_time, 'yyyy-MM-dd 00:00:00')
          // }
          if (msg.vh_cate_id != common.vh_cate.store) {
            return this.$dialog.alert({
              message: msg.plate_number + '不是储值车',
            }).then(() => {
              // on close
            })
          }
          this.$dialog.confirm({
            //  title: '标题',
            showCancelButton: false,
            message: `${msg.plate_number} ${msg.vh_cate_name} ${msg.vh_type_name}\n`
            + `延期时长：${this.storeForm.month == '长期有效' ? this.storeForm.month : this.storeForm.month + '个月' } \n`
            // + `充值时间：${common.fmtDate(this.payStoreParams.charge_start_time, 'yyyy-MM-dd')}至${this.addMonths(this.payStoreParams.charge_start_time, this.payStoreParams.month)}\n`
            + `充值金额：${this.storeForm.amount}元\n`
            + `请核对后，点击确认`,
            beforeClose: this.doPayStore,
          }).catch(e => console.log(e))
        } else {
          this.$dialog.alert({
            // title: '',
            message: msg.msg,
            theme: 'round-button'
          }).then(() => {
            console.log(msg)
          })
        }

      } else {
        this.$toast('请求出错')
      }
    },

    changePlateNumber(pn) {
      this.vhForm.plateNumber = pn
    },
    changeGroupName(pn) {
      this.groupForm.name = pn
    },
    changeStorePlate(pn) {
      this.storeForm.plateNumber = pn
    },
    clearVhHistory() {
      this.historyPlateNumbers = common.clearHistoryPlateNumber()
    },
    clearGroupHistory() {
      this.historyGroupName = common.clearHistoryGroupName()
    },
    clearStoreHistory() {
      this.historyStorePlate = common.clearHistoryStorePlate()
    },
    selectTab(info) {
      let showVh, showStore, showGroup
      if (info.showProlong == 'rent') {
        showVh = true
        showStore = false
        showGroup = false
      } else if (info.showProlong == 'store') {
        showVh = false
        showStore = true
        showGroup = false
      } else if (info.showProlong == 'group') {
        showVh = false
        showStore = false
        showGroup = true
      }

      switch (info.userTypeName) {
        case common.userTypesTxt.owner:
          this.tabs = {
            active: 0,
            showVh: showVh != undefined ? showVh : true,
            showStore: showStore != undefined ? showStore : true,
            showGroup: showGroup != undefined ? showGroup : true,
            disabled: false
          }
          break;
        case common.userTypesTxt.groupVhOwner:
          this.tabs = {
            active: 0,
            showVh: showVh != undefined ? showVh : false,
            showStore: showStore != undefined ? showStore : true,
            showGroup: showGroup != undefined ? showGroup : true,
            disabled: false
          }
          break;
        case common.userTypesTxt.park:
          this.tabs = {
            active: 0,
            showVh: showVh != undefined ? showVh : true,
            showStore: showStore != undefined ? showStore : true,
            showGroup: showGroup != undefined ? showGroup : true,
            disabled: false
          }
          break;
        case common.userTypesTxt.groupCompany:
          this.tabs = {
            active: 0,
            showVh: showVh != undefined ? showVh : true,
            showStore: showStore != undefined ? showStore : true,
            showGroup: showGroup != undefined ? showGroup : true,
            disabled: false
          }
          break;
        case common.userTypesTxt.agent:
          this.tabs = {
            active: 0,
            showVh: showVh != undefined ? showVh : true,
            showStore: showStore != undefined ? showStore : false,
            showGroup: showGroup != undefined ? showGroup : false,
            disabled: false
          }
          break;
      }
    },
    // 支付订单请求
    async doPay(params, done) {
      this.$toast.loading({
        mask: true,
        message: '请稍候...',
        duration: 5000
      })
      try {
        let payargs = await util.getPayargs(params, -1)
        if (util.get_user_agent() == 'wx') {
          this.wxReadyToPay(payargs, done)
        } else if (util.get_user_agent() == 'zfb') {
          this.zfbReadyToPay(payargs, done)
        }
      } catch (e) {
        console.log(e)
        done(false)
        this.$toast(JSON.stringify(e))
        // this.$toast('支付失败', JSON.stringify(e))
      }
    },
    // 微信支付相关
    WX_Bridge_ready(cb) { // 微信监听
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb());
          document.attachEvent('onWeixinJSBridgeReady', cb());
        }
      } else {
        cb()
      }
    },
    wxReadyToPay(payargs, cb) {
      var that = this
      if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(payargs, cb), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(payargs, cb));
          document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(payargs, cb));
        }
      } else {
        that.onBridgeReady(payargs, cb);
      }
    },
    onBridgeReady(payargs, done) {
      var that = this;
      // let pay_type = localStorage.getItem('pay_type');
      let pay_type = util.pay_type.bjyz
      if (pay_type == util.pay_type.wx) {  //微信直联
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": payargs.appId != null ? payargs.appId : that.appid,     //公众号名称，由商户传入
            "timeStamp": payargs.timeStamp || payargs.timestamp,         //时间戳，自1970年以来的秒数
            "nonceStr": payargs.nonceStr || payargs.nonce_str, //随机串
            "package": payargs.package,
            "signType": payargs.signType != null ? payargs.signType : "MD5",         //微信签名方式：
            "paySign": payargs.paySign || payargs.sign //微信签名
          },
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              window.location.replace('https://ac.yunzongnet.com/api/ac/477786592149311488')
            } else {
              that.$toast.fail('支付失败')
            }
          });
      } else if (pay_type == util.pay_type.bjyz) { //微信云纵间联
        let pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url
        }

        if ([
          common.userTypesTxt.groupCompany,
          common.userTypesTxt.park
        ].includes(this.userInfo.userTypeName)) {
          this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
        } else if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
          sessionStorage.setItem('adver_info', JSON.stringify(pars))
          window.location.replace(payargs.trade_url || payargs.pay_url)
        }
        typeof done == 'function' && done()
      } else if (pay_type == util.pay_type.pp) { //微信PP间联
        let pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url
        }

        if ([
          common.userTypesTxt.groupCompany,
          common.userTypesTxt.park
        ].includes(this.userInfo.userTypeName)) {
          this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
        } else if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
          sessionStorage.setItem('adver_info', JSON.stringify(pars))
          window.location.replace(payargs.trade_url || payargs.pay_url)
        }
        typeof done == 'function' && done()
      } else if (pay_type == util.pay_type.ningbobank) { //微信宁波银行间联
        let pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url
        }

        if ([
          common.userTypesTxt.groupCompany,
          common.userTypesTxt.park
        ].includes(this.userInfo.userTypeName)) {
          this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
        } else if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
          sessionStorage.setItem('adver_info', JSON.stringify(pars))
          window.location.replace(payargs.trade_url || payargs.pay_url)
        }
        typeof done == 'function' && done()
      }
    },
    // 支付宝支付相关
    ZFBReady(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    zfbReadyToPay(payargs, done) {
      let that = this
      this.ZFBReady(function () {
        let pay_type = util.pay_type.bjyz_zfb
        if (pay_type == util.pay_type.zfb) {  //支付宝直联
          window.ap.tradePay({
            tradeNO: payargs.tradeNo
          }, function (res) {
            if (res && res.resultCode == 9000) {
              window.AlipayJSBridge.call('pushWindow', {
                url: 'https://ac.yunzongnet.com/api/ac/477786592149311488',
                param: {
                  backBehavior: 'pop',
                  closeCurrentWindow: true
                }
              })
              done()
            } else {
              done(false)
              that.$toast.fail('支付失败')
            }
          })
        } else if (pay_type == util.pay_type.bjyz_zfb) { //支付宝云纵间联
          let pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url
          }
          if ([
            common.userTypesTxt.groupCompany,
            common.userTypesTxt.park
          ].includes(that.userInfo.userTypeName)) {
            this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
          } else if (that.userInfo.userTypeName == common.userTypesTxt.owner) {
            sessionStorage.setItem('adver_info', JSON.stringify(pars))
            // window.location.replace(payargs.trade_url);
            window.AlipayJSBridge.call('pushWindow', {
              url: payargs.trade_url || payargs.pay_url,
              param: {
                backBehavior: 'pop',
                closeCurrentWindow: true
              }
            })
          }
        } else if (pay_type == util.pay_type.pp_zfb) { //支付宝PP间联
          let pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url
          }
          if ([
            common.userTypesTxt.groupCompany,
            common.userTypesTxt.park
          ].includes(that.userInfo.userTypeName)) {
            this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
          } else if (that.userInfo.userTypeName == common.userTypesTxt.owner) {
            sessionStorage.setItem('adver_info', JSON.stringify(pars))
            // window.location.replace(payargs.trade_url);
            window.AlipayJSBridge.call('pushWindow', {
              url: payargs.trade_url || payargs.pay_url,
              param: {
                backBehavior: 'pop',
                closeCurrentWindow: true
              }
            })
          }
        } else if (pay_type == util.pay_type.pp_zfb) { //支付宝宁波银行间联
          let pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url
          }
          if ([
            common.userTypesTxt.groupCompany,
            common.userTypesTxt.park
          ].includes(that.userInfo.userTypeName)) {
            this.generatePayQrCode (payargs.trade_url || payargs.pay_url)
          } else if (that.userInfo.userTypeName == common.userTypesTxt.owner) {
            sessionStorage.setItem('adver_info', JSON.stringify(pars))
            // window.location.replace(payargs.trade_url);
            window.AlipayJSBridge.call('pushWindow', {
              url: payargs.trade_url || payargs.pay_url,
              param: {
                backBehavior: 'pop',
                closeCurrentWindow: true
              }
            })
          }
        }
      })
    },
    generatePayQrCode (url) {
      let that = this
      QRCode.toDataURL(
        url,
        { width: 200, height: 200 },
        function (err, dataUrl) {
          if (err) return that.$toast('出错了')
          that.qrShow = true
          that.qrImg = dataUrl
        }
      )
    },
    qrConfirm () {
      let topic = ''
      // this.$toast(this.topicSuccessFnVh)
      if (this.topicSuccessFnVh) {
        topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnVh}`
        mqtt.unsubscribe(topic)
      }
      if (this.topicSuccessFnGroup) {
        topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnGroup}`
        mqtt.unsubscribe(topic)
      }
    },
    payFinish (msg) {
      let m = '';
      if (msg && msg.code == common.resCode.OK) {
        m = '充值成功'
      } else {
        m = '充值失败'
      }
      this.$dialog.confirm({
        // title: '标题',
        showCancelButton: false,
        message: m,
        beforeClose: (confirm, done) => {
          console.log(confirm)
          done()
          this.$router.replace('/index')
        },
      }).catch(e => console.log(e))
    },
    async getPayWay(sid) {
      let {data} = await axios({
        url: common.payCloud + '/pub/pay.get_sid_pay_way',
        method: 'POST',
        data: {
          sid: sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
      console.log('data', data)
      if (data && data.code == common.resCode.OK) {
        if (data.sid_pay_way) {
          if (util.get_user_agent() == 'wx') {
            this.payType = data.sid_pay_way.wechat
          } else if (util.get_user_agent() == 'zfb') {
            this.payType = data.sid_pay_way.alipay
          }
        }
      } else {
        this.payType = null
      }
    }
  },
  beforeDestroy () {
    delete mqtt.Client.config[mqtt.topics.mq_query_charge_info]
    delete mqtt.Client.config[this.topicSuccessFnVh]
    delete mqtt.Client.config[this.topicSuccessFnGroup]
    let topics = []
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_info}`;
    topics.push(topic)
    topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnVh}`
    if (this.topicSuccessFnVh) topics.push(topic)
    topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${this.topicSuccessFnGroup}`
    if (this.topicSuccessFnGroup) topics.push(topic)
    mqtt.unsubscribe(topics).catch(e => console.log(e))
  },
  mounted () {
    this.isVueReady = true
    if (util.get_user_agent() == 'wx') {
      this.WX_Bridge_ready(() => {
        this.isTypeReady = true
      })
    } else if (util.get_user_agent() == 'zfb') {
      this.ZFBReady(() => {
        this.isTypeReady = true
      })
    }

    let userInfo = common.getQueryUserInfo.apply(this)
    console.log('userInfo', userInfo)
    this.selectTab (userInfo)
    this.historyPlateNumbers = common.getHistoryPlateNumber ()
    this.historyGroupName = common.getHistoryGroupName()
    this.historyStorePlate = common.getHistoryStorePlate()
    if (util.getProp('test')) {
      this.groupForm.moneyList.unshift(0.01)
      this.groupForm.amount = 0.01
      this.storeForm.moneyList.unshift(0.01)
      this.storeForm.amount = 0.01
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.recharge-prolong {
  @extend .w-100-h-100;

  background-color: $global-bg-color;
  .content-main {
    @extend .content-wrap-gap;
    .rp-title-wrap{
      padding: 1rem;
      display: flex;
      /*justify-content: center;*/
      /*align-items: flex-end;*/
      /*<!--color: $global-theme-blue;-->*/
      /*<!--background-color: $global-theme-bg-blue;-->*/
      .rp-title-label{
        margin-right: 1rem;
        font-size: $global-font-size-large;
        font-weight: 600;

      }
    }
    .rp-profile {
      padding: 0 0 10rem 0;
      .rp-top-tab{
        margin: -.2rem;
        border: 0;
        padding: 0;
        display: flex;
        .rp-top-tab-item{
          padding: .4rem 1rem;
          margin: 0;
          border-right: $global-color solid .2rem;
          border-bottom: $global-color solid .2rem;
        }
      }
    }
    .history-wrap{
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      margin-top: 1.6rem;
      padding: 0 1rem;
      display: flex;
      flex-wrap: wrap;
      /*background-color: white;*/
      .van-tag{
        margin-right: 1rem;
        margin-top: 1rem;
      }
    }
  }
  /deep/ .van-tab__text{
    font-size: $global-font-size-normal;
  }
  /deep/ .van-tab--active .van-tab__text{
    color: $global-theme-blue !important;
  }
  /deep/ .van-tabs__line{
    background-color: $global-theme-blue !important;
  }
}
</style>
