import axios from 'axios'
import * as common from "./common"
export const getProp = common.getProp
export const get_user_agent = () => {
  var ua = window.navigator.userAgent.toLowerCase();//获取判断用的对象
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return 'wx'
  } else if (ua.match(/AlipayClient/i) == 'alipayclient') {
    return 'zfb'
  }
}

export const getPayargs = (obj, openid) => {
  return new Promise(function (resolve, reject) {
    let user_agent = get_user_agent()
    console.log('user_agent', user_agent)
    let path
    if (user_agent == 'wx') {
      path = `pay.wx.charge`
    }
    if (user_agent == 'zfb') {
      path = `pay.zfb.charge`
    }

    axios({
      url: common.parkCloudQLUrl + '/s2p/' + path,
      method: 'POST',
      data: {
        type: obj.type,
        code: openid == null ? obj.code : openid,
        sid: obj.sid,
        plate_number: obj.plate_number,
        group_name: obj.group_name,
        gross_price: obj.gross_price,
        actually_paid: obj.actually_paid,
        subject: obj.subject,
        start_time: obj.start_time,
        end_time: obj.end_time,
        local_info: obj.local_info,
        sign: obj.sign
      },
      header: {
        'content-type': 'application/json' // 默认值
      }
    }).then(res => {
      console.log(res,'' +
        '获取支付')
      let d = res && res.data
      if (d.code == 10000) {
        let payargs = d.payargs
        resolve(payargs)
      } else {
        reject({code: d.code, msg: d.msg})
      }
    }).catch(e => {
      reject(e)
    })
  })
}



export const pay_type = {
  wx: 1, // 微信
  zfb: 2, // 支付宝
  paypal: 3, // paypal
  union: 4, // 银联
  xcx: 5,  // 小程序
  bjyz: 21, //北京云纵微信
  bjyz_zfb: 22,
  pp: 31,
  pp_zfb: 32,
  ningbobank: 61, //宁波银行支付【微信】
  ningbobank_zfb: 62 //宁波银行支付【支付宝】
}
